<template>
    <div>
        <app-page-header></app-page-header>
        <v-container  grid-list-xl class="box">
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md10 lg8 xl6>
                    <!--<registration-number-retention-time></registration-number-retention-time>-->
                    <div v-if="checkApplicationUserRole() == 'admin' ||checkApplicationUserRole() == 'root'">
                        <br>
                        <retention-time></retention-time>
                        <br>
                        <parking-spaces></parking-spaces>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { getUserApplicationRole, getUserApplicationPermissions, checkPermissions } from '@/helpers/utilities'
//const RegistrationNumberRetentionTime = () => import('@/components/RegistrationNumberRetentionTime.vue');
const RetentionTime = () => import('@/components/RetentionTime.vue')
const ParkingSpaces = () => import('@/components/ParkingSpaces.vue')

export default {
    components: {
        //RegistrationNumberRetentionTime,
        RetentionTime,
        ParkingSpaces
    },

    data: () => ({

    }),

    methods: {
        checkApplicationUserRole() {
            return getUserApplicationRole()
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 
    },

    mounted() {
        //window.console.log(this.checkApplicationUserRole());
    },

    destroyed() {

    }
}

</script>

<style scoped>
    .btn_rfid {
        margin-right:10px;
    }

    .v-card__text {
        color: rgba(0,0,0,0.90) !important;
    }
</style>